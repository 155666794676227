<template>
    <div>
        <navMenu></navMenu>
        <div class="header">
            <!-- <div class="header-l">
                <img v-if="showMenu" src="@/assets/fenlei/fenlei.png" alt="" @click="isShowMenu(0)">
                <img v-else src="@/assets/fenlei/ic_fenlei.png" alt="" @click="isShowMenu(1)">
                <img src="@/assets/slices/ic_shouye1.png" alt="" @click="goIndex">
            </div> -->
            <!-- <div class="header-r">
                <input placeholder="keywords" type="text">
                <img src="@/assets/slices/ic_sousuo.png" alt="">
            </div> -->
            <div class="header-l">
                <img src="@/assets/phone2/cute.png" alt="" @click="goIndex">
            </div>
            <div class="header-r">
                <img v-if="showMenu" src="@/assets/fenlei/fenlei.png" alt="" @click="isShowMenu(0)">
                <img v-else src="@/assets/fenlei/fenlei.png" alt="" @click="isShowMenu(1)">
            </div>

        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import navMenu from '@/components/navMenu'

    export default {
        components:{
            navMenu
        },
        data() {
            return {
                showMenu: false
            }
        },
        mounted() {
            eventBus.$on('showfenlei',i=>{
                this.showMenu = i
            })
        },
        methods: {
            isShowMenu(i){
                this.showMenu = i;
                eventBus.$emit('showmenu',i)
            },
            goIndex(){
                if(this.$route.path!='/') this.$router.push('/')
                else this.$router.go(0)
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .header{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .header{
    width: 100%;
  }
}
.header{
    padding: 0 13px;
    box-sizing: border-box;
    z-index: 999;
    height: 50px;
    background: #373952;
    border-radius: 1px;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    .header-l{
        img{
            width: 123px;
            height: 36px;
        }
    }
    .header-r{
        img{
            width: 35px;
            height: 35px;
        }
    }
    // .header-l{
    //     // position: absolute;
    //     // left: 8px;
    //     // top: 50%;
    //     // transform: translateY(-50%);
    //     display: flex;
    //     align-items: center;
    //     margin-left: 8px;
    //     img{
    //         width: 35px;
    //         height: 35px;
    //         margin-left: 8px;
    //     }
    // }
    // .header-r{
    //     // position: absolute;
    //     // right: 11px;
    //     // top: 50%;
    //     // transform: translateY(-50%);
    //     display: flex;
    //     align-content: center;
    //     width: 231px;
    //     height: 38px;
    //     margin-right: 11px;
    //     background: #DEDCFF;
    //     border-radius: 10px;
    //     input{
    //         margin-left: 12px;
    //         background-color: #DEDCFF;
    //         border: none;
    //         border-radius: 10px;
    //         outline: none;
    //         font-family: PingFangSC, PingFang SC;
    //         font-weight: 400;
    //         font-size: 16px;
    //         color: #AAADBE;
    //         line-height: 22px;
    //         text-align: left;
    //         font-style: normal;
    //     }
    // }
}
</style>