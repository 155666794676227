<template>
    <div class="nav-menu" v-if="showMenu">
        <div class="nav-menu-body">
            <div class="cont-search">
                <div class="search-l">
                    <input placeholder="keywords" v-model="searchText" type="text">
                </div>
                <img src="@/assets/phone2/sousuo.png" alt="" @click="searchGame()">
                <!-- <div class="search-r">
                </div> -->
            </div>
            <div :class="{
                'menu-item':true,
                'sel-color':selectMenu == item.key_name,
                'nsel-color':selectMenu!=item.key_name
            }" v-for="item in navList" :key="item.category" @click="clickMenu(item.key_name)">
                <div>{{item.key_name}}</div>
            </div>
        </div>
        <div class="mask" @click="closeMask"></div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { getClassList } from "@/api/games"
    export default {
        data() {
            return {
                navList:[],
                selectMenu:'Hot',
                showMenu:false,
                searchText: ''
            }
        },
        mounted() {
            this.getclasslist();
            eventBus.$on('showmenu',i=>{                
                this.showMenu = i;
            })
        },
        methods: {
            async getclasslist(){
                this.navList = await getClassList();
            },
            closeMask(){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false);
            },
            clickMenu(item){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false);
                this.selectMenu = item;
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                if(this.$route.path=='/selectGame'){
                    eventBus.$emit('searchgame',{
                        id:'more',
                        key:item
                    });
                }else{
                    this.$router.push({
                        path: '/selectGame',
                        query:{
                            id:'more',
                            key:item
                        }
                    })
                }
            },
            searchGame(){
                this.showMenu = false;
                eventBus.$emit('showfenlei',false)
                if(this.$route.path != '/selectGame'){
                    this.$router.push({
                        path: '/selectGame',
                        query: {
                            id: 'search',
                            key: this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchgame',{
                        id:'search',
                        key:this.searchText
                    });
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .nav-menu{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .nav-menu{
    width: 100%;
  }
}
.nav-menu{
    position: fixed;
    top: 50px;
    z-index: 99999;
    .nav-menu-body{
        &::-webkit-scrollbar{
            display: none;
        }
        height: 315px;
        background: linear-gradient( 93deg, #FFFFFF 0%, #EFFFFC 100%);
        border-radius: 1px;
        overflow: auto;
        .cont-search{
            display: flex;
            margin: 10px auto 11px;
            width: 338px;
            height: 40px;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            background: #D2FFF7;
            line-height: 40px;
            border-radius: 10px;
            .search-l{
                width: 281px;
                height: 40px;
                border-radius: 10px;
                overflow: hidden;
                input{
                    &::placeholder {
                        color: #55D2B8;
                    }
                    background: #D2FFF7;
                    margin-left: 13px;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    font-size: 16px;
                    color: #55D2B8;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                }
            }
            img{
                margin-right: 12px;
                width: 22px;
                height: 22px;
            }
        }
        .sel-color{
            color: #55D2B8;
        }
        .nsel-color{
            color: #373952;
        }
        .menu-item{
            cursor: pointer;
            margin: 0 auto;
            width: 315px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            line-height: 39px;
            text-align: center;
            font-style: normal;
            border-bottom: 1px solid #E7E7E7;
        }
    }
    .mask{
        height: calc(100vh - 365px);
        background: rgba(0, 0, 0, 0.5);

    }
}
</style>