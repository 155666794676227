import request from "@/utils/request";

export function getGameList(query){
    return request({
        url:'/homeGame.php',
        method: 'get',
        params: query
    })
}

export function getGameById(query) {
    return request({
        url: '/getGameId.php',
        method: 'get',
        params: query
    })
}

export function getGameByKey(query) {
    return request({
        url: '/category.php',
        method: 'get',
        params: query
    })
}

export function getClassList(query) {
    return request({
        url: '/categoryAll.php',
        method: 'get',
        params: query
    })
}

export function searchGame(query) {
    return request({
        url: '/search.php',
        method: 'get',
        params: query
    })
}

export function getGame(query) {
    return request({
        url: '/getGameList.php',
        method: 'get',
        params: query
    })
}

export function upLoadData(query) {
    return request({
        url: '/set.php',
        method: 'get',
        params: query
    })
}